<template>
  <div>
    <div class="fs-container" v-if="Slug=='about-itel'||Slug=='ve-itel'">
        <div class="fs-section about-banner">
            <div class="fs-inr">
                <div class="banner-title">
                    <h1>
                        #{{$t('StaticPage.mobile network tag Vn') }}<span>
                            <img loading="lazy" src="@/assets/images/itel-text.png" alt="itel">
                        </span>{{$t('StaticPage.mobile network tag En') }}
                    </h1>
                    <p>{{ $t('StaticPage.AboutItelDes') }}</p>
                </div>
                <div class="about-items">
                    <div class="about-item">
                        <div class="fs-pic"><img loading="lazy" src="@/assets/images/ve-itel/icon1.png" alt="#idea">
                        </div>
                        <div class="fs-txt">
                            <h2>#idea</h2>
                            <p v-html="$t('StaticPage.AboutItelDes2')"></p>
                        </div>
                    </div>
                    <div class="about-item">
                        <div class="fs-pic"><img loading="lazy" src="@/assets/images/ve-itel/icon2.png" alt="#trust">
                        </div>
                        <div class="fs-txt">
                            <h2>#trust</h2>
                            <p>{{ $t('StaticPage.AboutItelDes3') }}</p>
                        </div>
                    </div>
                    <div class="about-item">
                        <div class="fs-pic"><img loading="lazy" src="@/assets/images/ve-itel/icon3.png"
                                alt="#leading">
                        </div>
                        <div class="fs-txt">
                            <h2>#leading</h2>
                            <p v-html="$t('StaticPage.AboutItelDes4')"></p>
                        </div>
                    </div>
                    <div class="about-item">
                        <div class="fs-pic"><img loading="lazy" src="@/assets/images/ve-itel/icon4.png"
                                alt="#emotional">
                        </div>
                        <div class="fs-txt">
                            <h2>#emotional</h2>
                            <p>{{ $t('StaticPage.AboutItelDes5') }}</p>
                        </div>
                    </div>
                </div>
                <div class="banner-pic">
                    <img class="fs-pc pcPic" loading="lazy" src="@/assets/images/ve-itel/banner.jpg"
                        :alt="$t('StaticPage.bannerAlt')">
                    <img class="fs-sp spPic" loading="lazy" src="@/assets/images/ve-itel/banner-sp.jpg"
                        :alt="$t('StaticPage.bannerAlt')">
                </div>
            </div>
        </div>
        <div class="fs-section fs-mission">
            <div class="fs-inr mission-inr">
                <div class="fs-pic blue-circle"><img loading="lazy" src="@/assets/images/ve-itel/blue-circle.png"
                        alt="Circle"></div>
                <div class="block-title">
                    <h2>{{ $t('StaticPage.mission') }}</h2>
                    <p v-html="$t('StaticPage.mission2')"></p>
                </div>
                <div class="mission-items">
                    <div class="mission-item">
                        <h3>{{ $t('StaticPage.mission3') }}</h3>
                        <p>{{ $t('StaticPage.mission4') }}</p>
                        <div class="fs-pic"><img loading="lazy" src="@/assets/images/ve-itel/1.png"
                                :alt="$t('StaticPage.mission3')">
                        </div>
                    </div>
                    <div class="mission-item highlight">
                        <h3>{{ $t('StaticPage.mission5') }}</h3>
                        <p>{{ $t('StaticPage.mission6') }}</p>
                        <div class="fs-pic"><img loading="lazy" src="@/assets/images/ve-itel/2.png"
                                :alt="$t('StaticPage.mission5')">
                        </div>
                    </div>
                    <div class="mission-item">
                        <h3>{{ $t('StaticPage.mission7') }}</h3>
                        <p>{{ $t('StaticPage.mission8') }}</p>
                        <div class="fs-pic"><img loading="lazy" src="@/assets/images/ve-itel/3.png"
                                :alt="$t('StaticPage.mission7')">
                        </div>
                    </div>
                    <div class="mission-item">
                        <h3>{{ $t('StaticPage.mission9') }}</h3>
                        <p>{{ $t('StaticPage.mission10') }}</p>
                        <div class="fs-pic"><img loading="lazy" src="@/assets/images/ve-itel/4.png"
                                :alt="$t('StaticPage.mission9')">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fs-section fs-vision">
            <div class="fs-inr vision-inr">
                <div class="block-title">
                    <h2>{{ $t('StaticPage.vision') }}</h2>
                    <p>{{ $t('StaticPage.vision2') }}</p>
                    <p>{{ $t('StaticPage.vision3') }}</p>
                </div>
                <div class="fs-pic vision-pic">
                    <img loading="lazy" src="@/assets/images/ve-itel/vision-pattern.png"
                        :alt="$t('StaticPage.vision')">
                </div>
            </div>
        </div>
        <div class="fs-section fs-value">
            <div class="fs-inr value-inr">
                <div class="block-title">
                    <h2>{{ $t('StaticPage.coreValue') }}</h2>
                </div>
                <div class="value-items">
                    <div class="value-item">
                        <div class="fs-pic"><img loading="lazy" src="@/assets/images/ve-itel/087.png"
                                :alt="$t('StaticPage.coreValue2')"></div>
                        <div class="fs-txt">
                            <h3>{{ $t('StaticPage.coreValue2') }}</h3>
                        </div>
                    </div>
                    <div class="value-item">
                        <div class="fs-txt">
                            <h3>{{ $t('StaticPage.coreValue3') }}</h3>
                        </div>
                        <div class="fs-pic"><img loading="lazy" src="@/assets/images/ve-itel/itel.png"
                                :alt="$t('StaticPage.coreValue3')"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fs-section fs-promotion-video">
            <div class="fs-pic promotion-video-box">
                <div class="fs-title">
                    <h2>ITEL PROMOTION VIDEO</h2>
                    <div class="video-but type-red2 open-video-pop" @click="openVideo(staticPageDetail.VideoUrl)"></div>
                </div>
                <div class="fs-pic"><img loading="lazy" src="@/assets/images/ve-itel/banner-video.jpg"
                        alt="ITEL PROMOTION VIDEO"></div>
            </div>
        </div>

        <div class="fs-section culture-banner">
            <div class="fs-pic banner-pic">
                <img class="fs-pc pcPic" loading="lazy" src="@/assets/images/ve-itel/culture-banner.jpg"
                    :alt="$t('StaticPage.itelCulture')">
                <img class="fs-sp spPic" loading="lazy" src="@/assets/images/ve-itel/culture-banner-sp.jpg"
                    :alt="$t('StaticPage.itelCulture')">
                <img class="hand-pic" loading="lazy" src="@/assets/images/ve-itel/hand.png" alt="Hand">
                <img class="hand-txt" loading="lazy" :src="Img($t('StaticPage.cultureImg'))"
                    :alt="$t('StaticPage.Itel culture')">
            </div>
        </div>

        <div class="fs-section fs-distributions">
            <div class="fs-inr">
                <h2>{{ $t('StaticPage.ITel partner') }}</h2>
                <div class="distribution-items">
                    <a href="javascript:void(0)"><img loading="lazy" src="@/assets/images/ve-itel/vnpt.png"
                            alt="pic"></a>
                    <a href="javascript:void(0)"><img loading="lazy" src="@/assets/images/ve-itel/new-tel.png"
                            alt="pic"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="fs-container" v-else v-html="staticPageDetail.Detail">
    </div>
    <!--Video Popup-->
    <div :class="'modal '+modalClass" id="video-modal">
      <div class="overlay" @click="closeVideo()"></div>
      <div class="modal-inner">
        <a class="close-button" @click="closeVideo()"></a>
        <iframe :src="videoId" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>
    </div>
    <!--Video Popup-->


    <div class="faci-box" v-if="ConfigData.length>0">
      <a class="hotline-faci" :href="'tel:'+ConfigData.find(i=>i.DataKey=='PhoneWebsite').Value">
        <img loading="lazy" src="@/assets/images/sp-chat-tel.png" alt="hot line" />
      </a>
      <a class="chat-faci" href="https://zalo.me/pc" target="_blank" rel="noopener">
        <img loading="lazy" src="@/assets/images/sp-chat-icon.png" alt="hot chat" />
      </a>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import { isMobile } from 'mobile-device-detect';

// Import Swiper styles
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination])
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images['assets/images/ve-itel/'+item.replace('./', '')] = r(item);    
  });
  return images;
}
const images = importAll(require.context('@/assets/images/ve-itel/', false, /.(png)$/));


export default {
  components: {
    Swiper, SwiperSlide
  },
  data() {
    return {
      staticPageDetail:{},
      ConfigData:[],
      videoId:'',
      modalClass:'',
      Slug: '',
    }
  },
  watch: {
    $route(to, from) {
      this.Slug = to.params.Slug;
      console.log(to);
      this.fetch();
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted(){
    this.Slug = this.$route.params.Slug;
    this.setAnimation();
    this.fetch();
    var t_ = this;
    EventBus.$on('CHANGE_LANG', function (payLoad) {
      t_.fetch();
    });
    
    document.addEventListener('scroll', (e) => {
      this.setAnimation();
    })
  },
  methods: {
    Img(pic){
      return images[pic];
    },
    fetch(){
      this.loading(true);
      useJwt.post('getMasterData/staticPageDetail',{columnFilters:{Slug:this.Slug},sort:[],page:1,pageSize:1000,lang:this.$l}).then(response =>{
        if(response.data.result.length>0){
          this.staticPageDetail = response.data.result[0];
          console.log(this.staticPageDetail.Detail)
          if (this.staticPageDetail.Detail!=null){
            this.staticPageDetail.Detail = this.staticPageDetail.Detail.replace(/src="\/UploadTemp/g,'src="'+this.staticPageDetail.cdnUrl+'/UploadTemp');
          }
        }
        this.loading(false);
      }).catch(err=>{
        this.loading(false);
        this.$toast.error('Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!', {icon:true, closeButton: "button"})
      })
    },
    openVideo(url){
      this.modalClass = 'modal__open';
      this.videoId = 'https://www.youtube.com/embed/' + url + '?rel=0&amp;autoplay=1&amp;playsinline=1';
    },
    closeVideo(){
      this.videoId='';
      this.modalClass = '';
    },
    setAnimation() {
      const windowH = window.screen.height;
      const headH = document.querySelector('.fs-header').clientHeight;
      [].slice.call(document.querySelectorAll('.fs-banner, .choose-pic, .choose-txt, .pack-txt, .pack-info, .i-news-infos, .video-box, .support-pic, .support-txt, .like-pic, .welcome-inr .fs-txt, .welcome-inr .fs-pic, .why-inr .fs-pic, .item-reseaon, .hand-pic, .hand-txt,.about-item, .block-title, .mission-item, .value-item, .promotion-video-box, .vision-inr, .fs-distributions .fs-inr')).forEach((elm) => {
        const style = window.getComputedStyle(elm, null);
        const elmH = style.getPropertyValue('height');
        const inputT = windowH - elm.getBoundingClientRect().top;
        if (inputT > 0 && inputT < (windowH + parseInt(elmH, 10) - headH / 2)) {
          elm.classList.add('fs-ani');
        } else {
          elm.classList.remove('fs-ani');
        }
      });
    }
  }
}
</script>
